import React from "react";
import { Link } from "react-router-dom";
import CareersSinglePage from "./CareersSinglePage";
const RandDService = () => {
  return (
    <div>
      <div className="page-title">
        <div
          className="pattern-layer-one"
          // style={{
          //   backgroundImage:
          //     "url(../../assets/Images/background/pattern-16.png)",
          // }}
        ></div>
        <div className="auto-container">
          <h2>CAREERS</h2>
          <ul className="page-breadcrumb">
            <li>
              <Link to="/">Home&gt;</Link>
            </li>
            <li>Careers</li>
          </ul>
        </div>
      </div>
      <div className="careerpage">
        <div className="auto-container">
          <div className="upper-section">
            <div className="row clearfix">
              <div className="image-column col-lg-7 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    <div className="title_name"> R & D DEVELOPER</div>
                    <div className="location">
                      <h2>
                        <i className="ri-crosshair-2-line"></i> #58-12-13/1, 2nd
                        Floor, Sanjeevanagar, Rajahmundry-533105.
                      </h2>
                      {/* <h2><i className="ri-money-rupee-circle-line"></i> 1 - 4 lacs</h2> */}
                    </div>
                    <p>We are looking for a R and D Developer </p>
                  </div>
                  <div className="jobdescript">
                    <div className="title">Job Description</div>
                    <p>
                      Conduct research and development activities to innovate
                      and enhance products. Collaborate with cross-functional
                      teams, perform feasibility studies, prototype testing, and
                      ensure alignment with market needs and company goals.
                    </p>
                  </div>
                  <div className="jobdescript">
                    <div className="title">Job Requirements</div>
                    <ul>
                      <li>
                        Proficiency in relevant software and tools, such as CAD
                        for design, statistical software for analysis, and
                        project management tools.
                      </li>
                      <li>
                        Ability to generate new ideas, think creatively, and
                        drive product innovation to meet market demands.
                      </li>
                      <li>
                        Experience with rapid prototyping, testing procedures,
                        and quality assurance.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="info-column col-lg-5 col-md-12 col-sm-12">
                <div className="contact-form">
                  <form method="post" id="contact-form">
                    <CareersSinglePage />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RandDService;
