// src/components/Loader.js
import React from "react";
import "./Loader.css"; // Import the loader styling

const Loader = () => (
  <div className="d-fl">
    <div className="loader"></div>
  </div>
);

export default Loader;
