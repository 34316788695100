import React from "react";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Sponsor = () => {
  return (
    <div>
      <div class="sponsors-section">
        <div class="auto-container">
          <div class="carousel-outer">
            <Carousel
              responsive={responsive}
              infinite={true}
              showDots={true}
              arrows={false}
              autoPlay={true}
              autoPlaySpeed={2000}
            >
              <div class="image-box">
                <Link to="">
                  <img src="../../assets/Images/clients/1.png" alt="" />
                </Link>
              </div>
              <div class="image-box">
                <Link to="">
                  <img src="../../assets/Images/clients/2.png" alt="" />
                </Link>
              </div>
              <div class="image-box">
                <Link to="">
                  <img src="../../assets/Images/clients/3.png" alt="" />
                </Link>
              </div>
              <div class="image-box">
                <Link to="">
                  <img src="../../assets/Images/clients/4.png" alt="" />
                </Link>
              </div>
              <div class="image-box">
                <Link to="">
                  <img src="../../assets/Images/clients/1.png" alt="" />
                </Link>
              </div>
              <div class="image-box">
                <Link to="">
                  <img src="../../assets/Images/clients/2.png" alt="" />
                </Link>
              </div>
              <div class="image-box">
                <Link to="">
                  <img src="../../assets/Images/clients/3.png" alt="" />
                </Link>
              </div>
              <div class="image-box">
                <Link to="">
                  <img src="../../assets/Images/clients/4.png" alt="" />
                </Link>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sponsor;
