import React from "react";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1025 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1025, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const Banner = () => {
  return (
    <div>
      <div class="banner-section">
        <Carousel
          responsive={responsive}
          arrows={false}
          showDots={true}
          autoPlay={true}
          infinite={true}
          autoPlaySpeed={2000}
          class="main-slider-carousel .owl-carousel .owl-item"
        >
          <div
            class="slide"
            style={{
              backgroundImage: "url(../../assets/Images/main-slider/iot.png)",
            }}
          >
            <div
              class="patern-layer-one"
              style={{
                backgroundImage:
                  "url(../../assets/Images/main-slider/pattern-1.png)",
              }}
            ></div>
            <div
              class="patern-layer-two"
              style={{
                backgroundImage:
                  "url(../../assets/Images/main-slider/pattern-2.png)",
              }}
            ></div>
            <div class="auto-container">
              {/* <!-- Content Column --> */}
              <div class="content-column">
                <div class="inner-column">
                  <div
                    class="patern-layer-three"
                    style={{
                      backgroundImage:
                        "url(../../assets/Images/main-slider/pattern-3.png)",
                    }}
                  ></div>
                  <div class="title">
                    Transforming Ideas into Smart Solutions.
                  </div>
                  <h1>
                    Empowering businesses with innovative,
                    <br />
                    data-driven IoT solutions.
                  </h1>
                  <div class="text">
                    Leverage connected devices and real-time insights
                    <br />
                    to optimize operations and enhance customer experiences.
                  </div>
                  {/* <div class="btns-box">
                    <Link to="" class="theme-btn btn-style-one">
                      <span class="txt">Learn More</span>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div
            class="slide"
            style={{
              backgroundImage: "url(../../assets/Images/main-slider/web.png)",
            }}
          >
            <div
              class="patern-layer-one"
              style={{
                backgroundImage:
                  "url(../../assets/Images/main-slider/pattern-1.png)",
              }}
            ></div>
            <div
              class="patern-layer-two"
              style={{
                backgroundImage:
                  "url(../../assets/Images/main-slider/pattern-2.png)",
              }}
            ></div>
            <div class="auto-container">
              {/* <!-- Content Column --> */}
              <div class="content-column">
                <div class="inner-column">
                  <div
                    class="patern-layer-three"
                    style={{
                      backgroundImage:
                        "url(../../assets/Images/main-slider/pattern-3.png)",
                    }}
                  ></div>
                  <div class="title">
                    Building the Future of Digital Experiences
                  </div>
                  <h1>
                    Creating responsive, user-friendly websites <br />
                    that drive results for your business.
                  </h1>
                  <div class="text">
                    From design to deployment, we build robust, scalable
                    <br />
                    web solutions tailored to your unique business needs.
                  </div>
                  {/* <div class="btns-box">
                    <Link to="" class="theme-btn btn-style-one">
                      <span class="txt">Learn More</span>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div
            class="slide"
            style={{
              backgroundImage: "url(../../assets/Images/main-slider/app.png)",
            }}
          >
            <div
              class="patern-layer-one"
              style={{
                backgroundImage:
                  "url(../../assets/Images/main-slider/pattern-1.png)",
              }}
            ></div>
            <div
              class="patern-layer-two"
              style={{
                backgroundImage:
                  "url(../../assets/Images/main-slider/pattern-2.png)",
              }}
            ></div>
            <div class="auto-container">
              {/* <!-- Content Column --> */}
              <div class="content-column">
                <div class="inner-column">
                  <div
                    class="patern-layer-three"
                    style={{
                      backgroundImage:
                        "url(../../assets/Images/main-slider/pattern-3.png)",
                    }}
                  ></div>
                  <div class="title">
                    Empower Your Business with
                    <br />
                    Custom Mobile Apps
                  </div>
                  {/* <h1>
                    Seamless, intuitive mobile app solutions to enhance user
                    engagement and growth.
                  </h1> */}
                  <div class="text">
                    From design to deployment, we build <br /> high-performance
                    mobile apps
                    <br />
                    that connect brands with their audiences effectively.
                  </div>
                  {/* <div class="btns-box">
                    <Link to="" class="theme-btn btn-style-one">
                      <span class="txt">Learn More</span>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div
            class="slide"
            style={{
              backgroundImage:
                "url(../../assets/Images/main-slider/digital-marketing.png)",
            }}
          >
            <div
              class="patern-layer-one"
              style={{
                backgroundImage:
                  "url(../../assets/Images/main-slider/pattern-1.png)",
              }}
            ></div>
            <div
              class="patern-layer-two"
              style={{
                backgroundImage:
                  "url(../../assets/Images/main-slider/pattern-2.png)",
              }}
            ></div>
            <div class="auto-container">
              {/* <!-- Content Column --> */}
              <div class="content-column">
                <div class="inner-column">
                  <div
                    class="patern-layer-three"
                    style={{
                      backgroundImage:
                        "url(../../assets/Images/main-slider/pattern-3.png)",
                    }}
                  ></div>
                  <div class="title">
                    Maximize Your Reach with Strategic Digital Marketing
                    Services
                  </div>
                  <h1>
                    Boost online presence with <br /> results-driven marketing.
                  </h1>
                  <div class="text">
                    Targeted strategies to boost visibility,
                    <br /> drive traffic, and engagement.
                  </div>
                  {/* <div class="btns-box">
                    <Link to="" class="theme-btn btn-style-one">
                      <span class="txt">Learn More</span>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default Banner;
