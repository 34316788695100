import React from "react";
import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./Components/Common/Header";
import Footer from "./Components/Common/Footer";
import Home from "./Pages/Home.jsx";
import Banner from "./Pages/Banner.jsx";
import About from "./Pages/About.jsx";
import Feature from "./Pages/Feature.jsx";
import Service1 from "./Pages/Service1.jsx";
import Case from "./Pages/Case.jsx";
import Sponsor from "./Pages/Sponsor.jsx";
import Testimonials from "./Pages/Testimonials.jsx";
import Products from "./Pages/Products.jsx";
import AboutUs from "./Pages/AboutUs.jsx";
import ContactUs from "./Pages/ContactUs.jsx";
import Privacy from "./Pages/Privacy.jsx";
import "./assets/css/style.css";
import "./assets/css/animate.css";
import "./assets/css/animation.css";
import "./assets/css/bootstrap.css";
import "./assets/css/color-switcher-design.css";
import "./assets/css/custom-animate.css";
import "./assets/css/jquery-ui.css";
import "./assets/css/jquery.bootstrap-touchspin.css";
import "./assets/css/style2.css";
import Careers from "./Pages/Careers.jsx";
import CareersSinglePage from "./Pages/CareersSinglePage.jsx";
import Service from "./Pages/Service.jsx";
import ProductSinglePage from "./Pages/ProductSinglePage.jsx";
import ServiceIot from "./Pages/ServiceIot.jsx";
import RandDService from "./Pages/RandDService.jsx";
import DigitalMarketing from "./Pages/DigitalMarketing.jsx";
import WebDev from "./Pages/WebDev.jsx";
import MobileDev from "./Pages/MobileDev.jsx";
import Blog1 from "./Pages/Blog1.jsx";
import Blog2 from "./Pages/Blog2.jsx";
import Blog3 from "./Pages/Blog3.jsx";
import Blog4 from "./Pages/Blog4.jsx";
import ProductDev from "./Pages/ProductDev.jsx";
import Loader from "./Components/Common/loader/Loaders.jsx";
import ScrollToTop from "./Components/Common/ScrollToTop.jsx";

const App = () => {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <>
          <Header />
          <ScrollToTop />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Banner" element={<Banner />} />
            <Route path="/About" element={<About />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/Feature" element={<Feature />} />
            <Route path="/Service1" element={<Service1 />} />
            <Route path="/Blog1" element={<Blog1 />} />
            <Route path="/Blog2" element={<Blog2 />} />
            <Route path="/Blog3" element={<Blog3 />} />
            <Route path="/Blog4" element={<Blog4 />} />
            <Route path="/Case" element={<Case />} />
            <Route path="/ServiceIot" element={<ServiceIot />} />
            <Route path="/RandDService" element={<RandDService />} />
            <Route path="/DigitalMarketing" element={<DigitalMarketing />} />
            <Route path="/Sponsor" element={<Sponsor />} />
            <Route path="/WebDev" element={<WebDev />} />
            <Route path="/MobileDev" element={<MobileDev />} />
            <Route path="/ProductDev" element={<ProductDev />} />
            <Route path="/Careers" element={<Careers />} />
            <Route path="/CareerSinglePage" element={<CareersSinglePage />} />
            <Route path="/Service" element={<Service />} />
            <Route path="/ProductSinglePage" element={<ProductSinglePage />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/Testimonals" element={<Testimonials />} />
            <Route path="/Products" element={<Products />} />
            <Route path="/Privacy" element={<Privacy />} />
          </Routes>
          <Footer />
        </>
      )}
    </div>
  );
};

export default App;
