import React from "react";
import { Link } from "react-router-dom";
const Careers = () => {
  return (
    <div>
      <div class="page-title">
        <div
          class="pattern-layer-one"
          // style={{
          //   backgroundImage:
          //     "url(../../assets/Images/background/pattern-16.png)",
          // }}
        ></div>
        <div class="auto-container">
          <h2>CAREERS</h2>
          <ul class="page-breadcrumb">
            <li>
              <Link to="/">Home &gt;</Link>
            </li>
            <li>Careers</li>
          </ul>
        </div>
      </div>
      <div class="auto-container">
        <div class="row clearfix">
          {/* <!-- Service Block --> */}
          <div class="service-block col-lg-3 col-md-6 col-sm-12">
            <div class="inner-box">
              <h5>
                <Link to="">
                  IoT <br />
                  Services
                </Link>
              </h5>
              <div class="icon-box">
                <ul>
                  <li>C</li>
                  <li>C++</li>
                  <li>Python</li>
                  <li>+2</li>
                </ul>
              </div>
              <div class="text">
                IoT services connect devices, collect data, and optimize
                operations for improved efficiency and insights.
              </div>
              <Link to="/ServiceIot">
                <span>
                  View More <i class="ri-arrow-right-s-line"></i>
                </span>
              </Link>
            </div>
          </div>

          {/* <!-- Service Block --> */}
          <div class="service-block col-lg-3 col-md-6 col-sm-12">
            <div class="inner-box">
              <h5>
                <Link to="">
                  R & D <br />
                  Services
                </Link>
              </h5>
              <div class="icon-box">
                <ul>
                  <li>C++</li>
                  <li>Cad</li>
                  <li>Python</li>
                  <li>+2</li>
                </ul>
              </div>
              <div class="text">
                R&D services drive innovation through research, product
                development, testing, and market analysis.
              </div>
              <Link to="/RandDService">
                <span>
                  View More <i class="ri-arrow-right-s-line"></i>
                </span>
              </Link>
            </div>
          </div>
          {/* <!-- Service Block --> */}
          <div class="service-block col-lg-3 col-md-6 col-sm-12">
            <div class="inner-box">
              <h5>
                <Link to="">
                  Product <br /> Development
                </Link>
              </h5>
              <div class="icon-box">
                <ul>
                  <li>Adobe</li>
                  <li>Figma</li>
                  <li>Python</li>
                  <li>+2</li>
                </ul>
              </div>
              <div class="text">
                Product development services encompass ideation, design,
                prototyping, testing, and market launch for innovative
                solutions.
              </div>
              <Link to="/ProductDev">
                <span>
                  View More <i class="ri-arrow-right-s-line"></i>
                </span>
              </Link>
            </div>
          </div>
          {/* <!-- Service Block --> */}
          <div class="service-block col-lg-3 col-md-6 col-sm-12">
            <div class="inner-box">
              <h5>
                <Link to="">Web Design & Development</Link>
              </h5>
              <div class="icon-box">
                <ul>
                  <li>Html</li>
                  <li>Css</li>
                  <li>Js</li>
                  <li>+2</li>
                </ul>
              </div>
              <div class="text">
                Web designer and developer services create responsive websites,
                enhance user experience,functionality.
              </div>
              <Link to="/WebDev">
                <span>
                  View More <i class="ri-arrow-right-s-line"></i>
                </span>
              </Link>
            </div>
          </div>
        </div>
        <div className="row clearfix" style={{ justifyContent: "center" }}>
          {/* <!-- Service Block --> */}
          <div class="service-block col-lg-3 col-md-6 col-sm-12">
            <div class="inner-box">
              <h5>
                <Link to="">Mobile Application Development</Link>
              </h5>
              <div class="icon-box">
                <ul>
                  <li>Swift</li>
                  <li>Kotlin</li>
                  <li>Js</li>
                  <li>+2</li>
                </ul>
              </div>
              <div class="text">
                Mobile application services design,maintain apps for iOS and
                Android, ensuring optimal user experience.
              </div>
              <Link to="/MobileDev">
                <span>
                  View More <i class="ri-arrow-right-s-line"></i>
                </span>
              </Link>
            </div>
          </div>
          {/* <!-- Service Block --> */}
          <div class="service-block col-lg-3 col-md-6 col-sm-12">
            <div class="inner-box">
              <h5>
                <Link to="">
                  Digital <br /> Marketing
                </Link>
              </h5>
              <div class="icon-box">
                <ul>
                  <li>SEO</li>
                  <li>SEM</li>
                  <li>CMS</li>
                  <li>+2</li>
                </ul>
              </div>
              <div class="text">
                Digital marketing services enhance brand visibility through
                SEO,targeted advertising strategies.
              </div>
              <Link to="/DigitalMarketing">
                <span>
                  View More <i class="ri-arrow-right-s-line"></i>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers;
