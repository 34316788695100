// import axios from "axios";
// export const profileData = axios.create({
//   baseURL: "http://localhost/vincosys.com_website/backend/api/",
//   headers: {
//     "Content-Type": "application/json",
//   },
// });
// export const services = axios.create({
//   baseURL: "http://localhost/vincosys.com_website/backend/api/",
//   headers: {
//     "Content-Type": "application/json",
//   },
// });
// export const Data = axios.create({
//   baseURL: "http://localhost/vincosys.com_website/backend/api/",
//   headers: {
//     "Content-Type": "application/json",
//   },
// });
// export const productType = axios.create({
//   baseURL: "http://localhost/vincosys.com_website/backend/api/",
//   headers: {
//     "Content-Type": "application/json",
//   },
// });
// export const products = axios.create({
//   baseURL: "http://localhost/vincosys.com_website/backend/api/",
//   headers: {
//     "Content-Type": "application/json",
//   },
// });
// export const contact = axios.create({
//   baseURL: "http://localhost/vincosys.com_website/backend/api/",
//   headers: {
//     "Content-Type": "application/json",
//   },
// });
// export const imgUrl =
//   "http://localhost/vincosys.com_website/backend/assets/uploads/";

import axios from "axios";
export const profileData = axios.create({
  baseURL: "https://vincosys.com/backend/api/",
  headers: {
    "Content-Type": "application/json",
  },
});
export const services = axios.create({
  baseURL: "https://vincosys.com/backend/api/",
  headers: {
    "Content-Type": "application/json",
  },
});
export const Data = axios.create({
  baseURL: "https://vincosys.com/backend/api/",
  headers: {
    "Content-Type": "application/json",
  },
});
export const productType = axios.create({
  baseURL: "https://vincosys.com/backend/api/",
  headers: {
    "Content-Type": "application/json",
  },
});
export const products = axios.create({
  baseURL: "https://vincosys.com/backend/api/",
  headers: {
    "Content-Type": "application/json",
  },
});
export const contact = axios.create({
  baseURL: "https://vincosys.com/backend/api/",
  headers: {
    "Content-Type": "application/json",
  },
});
export const imgUrl = "https://vincosys.com/backend/assets/uploads/";
