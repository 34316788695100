import React from "react";
import { Link } from "react-router-dom";
import { productType, imgUrl } from "../utils/environments/api";
import { useState } from "react";
import { useEffect } from "react";
const Case = () => {
  const id = new URLSearchParams(window.location.search).get("id");
  const [type, setType] = useState([]);
  useEffect(() => {
    ProductType();
  }, []);
  const ProductType = async () => {
    try {
      const res = await productType.get(`get_product_typ_api.php`);
      console.log("API Response:", res);

      if (res.data.data) {
        setType(res.data.data);
      } else {
        console.error("Invalid response format:", res);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  return (
    <div>
      <div class="cases-section">
        <div class="auto-container">
          {/* <!-- Sec Title --> */}
          <div class="sec-title centered">
            <div class="pro_title">LATEST PRODUCTS</div>
            <h2>
              Our Products <br />
            </h2>
          </div>
          <div class="row clearfix">
            {/* <!-- Case Block --> */}
            {type.map((item) => (
              <div class="case-block col-lg-4 col-md-6 col-sm-12">
                <div class="inner-box">
                  <div class="image">
                    <img src={`${imgUrl}${item.image}`} alt="" />
                    <div class="overlay-box">
                      <div class="content">
                        <h4>
                          <Link to="">{item.product}</Link>
                        </h4>
                        <div class="category">Products</div>
                      </div>
                      <Link
                        to={`/ProductSinglePage?id=${item.producttype_id}`}
                        class="arrow"
                      >
                        <i class="ri-arrow-right-fill"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* <div class="section-text">
            We Have Done More Than 1K Projects in Last 3 Years, With 100%
            Satisfaction.
          </div> */}

            <div class="btn-box text-center">
              {/* <Link to="" class="theme-btn btn-style-three">
                <span class="txt">View All</span>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Case;
