import React from "react";
import { Link } from "react-router-dom";
import { contact } from "../utils/environments/api";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ContactUs = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    number: "",
    webiste: "",
    message: "",
  });

  // Function to handle input changes for text fields
  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const postcontact = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
      const res = await contact.post("post_contact_api.php", form);

      console.log("API Response:", res);
      if (res.data.status === true) {
        toast("Submitted Successfully!");
      } else {
        const errorMessage = res.data.message || "An unknown error occurred";
      }
    } catch (error) {
      console.error("Error during post:", error);
      const errorDetail = error.response
        ? JSON.stringify(error.response.data)
        : error.message;
    }
  };
  return (
    <div>
      <div class="page-title">
        <div
          class="pattern-layer-one"
          // style={{
          //   backgroundImage:
          //     "url(../../assets/Images/background/pattern-16.png)",
          // }}
        ></div>
        <div class="auto-container">
          <h2>Contact us</h2>
          <ul class="page-breadcrumb">
            <li>
              <Link to="/">Home &gt;</Link>
            </li>
            <li>Contact us</li>
          </ul>
        </div>
      </div>
      <div class="contact-info-section">
        <div class="auto-container">
          <div class="title-box">
            <div class="title">GET IN TOUCH</div>
            <h2>
              A Monthly Project Fee <br />
              Price Plans
            </h2>
            <div class="text">
              For genereal enquires you can touch with our front desk supporting
              team <br />
              at <Link to="">info@vincosys.com </Link> or call on{" "}
              <Link to="">9100625636</Link>
            </div>
          </div>

          <div class="row clearfix">
            <div class="info-column col-lg-4 col-md-6 col-sm-12">
              <div class="inner-column">
                <div class="content">
                  <div class="icon-box">
                    <span>
                      <i class="ri-map-pin-line"></i>
                    </span>
                  </div>
                  <ul>
                    <li>
                      <strong>Address</strong>
                    </li>
                    <li>
                      #58-12-13/1,2nd Floor, Sanjeevanagar, Rajahmundry 533105
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="info-column col-lg-4 col-md-6 col-sm-12">
              <div class="inner-column">
                <div class="content">
                  <div class="icon-box">
                    <span>
                      <i class="ri-phone-line"></i>
                    </span>
                  </div>
                  <ul>
                    <li>
                      <strong>Phone</strong>
                    </li>
                    <li>
                      +91 9100625636 <br />
                      Mon-Fri, 10AM to 6:30PM
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="info-column col-lg-4 col-md-6 col-sm-12">
              <div class="inner-column">
                <div class="content">
                  <div class="icon-box">
                    <span>
                      <i class="ri-mail-line"></i>
                    </span>
                  </div>
                  <ul>
                    <li>
                      <strong>E-Mail</strong>
                    </li>
                    <li>
                      info@vincosys.com <br />
                      24/7 Support
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="lower-text">
            We are at 4 places over the country,
            <Link to="#">see our branches.</Link>
          </div>
        </div>
      </div>

      <div class="contact-map-section">
        <div class="auto-container">
          <div class="sec-title">
            <div class="clearfix">
              <div class="pull-left">
                <div class="title">SEND YOUR MESSAGE</div>
                <h2>Send Your Message</h2>
              </div>
              <div class="pull-right">
                <div class="text">
                  Our goal is to help our companies maintain or achieve best-
                  in-class <br />
                  positions in their respective industries and our team works.
                </div>
              </div>
            </div>
          </div>
          <div class="contact-form">
            <form method="post" id="contact-form">
              <div class="row clearfix">
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                  <label>Your name *</label>
                  <input
                    type="text"
                    name="name"
                    placeholder=""
                    onChange={onChange}
                    required
                  />
                </div>

                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                  <label>Email address *</label>
                  <input
                    type="text"
                    name="email"
                    placeholder=""
                    onChange={onChange}
                    required
                  />
                </div>

                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                  <label>Phone number *</label>
                  <input
                    type="text"
                    name="number"
                    placeholder=""
                    onChange={onChange}
                    required
                  />
                </div>

                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                  <label>Website</label>
                  <input
                    type="text"
                    name="website"
                    placeholder=""
                    onChange={onChange}
                    required
                  />
                </div>

                <div class="form-group col-lg-12 col-md-12 col-sm-12">
                  <label>Your Message *</label>
                  <textarea
                    name="message"
                    placeholder=""
                    onChange={onChange}
                  ></textarea>
                </div>

                <div class="form-group text-center col-lg-12 col-md-12 col-sm-12">
                  <button
                    class="theme-btn btn-style-three"
                    type="submit"
                    name="submit-form"
                  >
                    <span class="txt" onClick={postcontact}>
                      Send Now
                    </span>
                  </button>
                  <ToastContainer />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
