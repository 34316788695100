import React from 'react'
import { Link } from 'react-router-dom'
const Team = () => {
  return (
    <div>
       <div
              class="team-section"
              style={{ backgroundImage:'url(../../assets/Images/background/2.jpg)' }}      >
        <div class="auto-container">
          {/* <!-- Sec Title --> */}
          <div class="sec-title">
            <div class="clearfix">
              <div class="pull-left">
                <div class="title">OUR DEDICATED TEAM</div>
                <h2>
                  We have Large No <br />
                  of Expert Team Member
                </h2>
              </div>
              <div class="pull-right">
                <div class="text">
                  Our goal is to help our companies maintain or achieve best-
                  in-class <br />
                  positions in their respective industries and our team works.
                </div>
              </div>
            </div>
          </div>
          <div class="clearfix">
            {/* <!-- Team Block --> */}
            <div class="team-block col-lg-3 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <Link to=""><img src="../../assets/Images/resource/team-1.jpg" alt=""
                  /></Link>
                </div>
                <div class="lower-box">
                  {/* <!-- Social Box --> */}
                  <ul class="social-box">
                    <li><Link to=""><i class="ri-facebook-fill"></i></Link></li>
                    <li><Link to=""><i class="ri-twitter-fill"></i></Link></li>
                    <li><Link to=""><i class="ri-dribbble-fill"></i></Link></li>
                  </ul>
                  <div class="content">
                    <h5><Link to="">Jennifer Garcia</Link></h5>
                    <div class="designation">Consultant Officer</div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Team Block --> */}
            <div class="team-block col-lg-3 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <Link to=""
                    ><img src="../../assets/Images/resource/team-2.jpg" alt=""
                  /></Link>
                </div>
                <div class="lower-box">
                  {/* <!-- Social Box --> */}
                  <ul class="social-box">
                    <li><Link to=""><i class="ri-facebook-fill"></i></Link></li>
                    <li><Link to=""><i class="ri-twitter-fill"></i></Link></li>
                    <li><Link to=""><i class="ri-dribbble-fill"></i></Link></li>
                  </ul>
                  <div class="content">
                    <h5><Link to="">Robert Liam</Link></h5>
                    <div class="designation">Web Designer</div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Team Block --> */}
            <div class="team-block col-lg-3 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <Link to=""
                    ><img src="../../assets/Images/resource/team-3.jpg" alt=""
                  /></Link>
                </div>
                <div class="lower-box">
                  {/* <!-- Social Box --> */}
                  <ul class="social-box">
                    <li><Link to=""><i class="ri-facebook-fill"></i></Link></li>
                    <li><Link to=""><i class="ri-twitter-fill"></i></Link></li>
                    <li><Link to=""><i class="ri-dribbble-fill"></i></Link></li>
                  </ul>
                  <div class="content">
                    <h5><Link to="">Jassica Ethan</Link></h5>
                    <div class="designation">Project Manager</div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Team Block --> */}
            <div class="team-block col-lg-3 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <Link to=""
                    ><img src="I../../assets/Images/resource/team-4.jpg" alt=""
                  /></Link>
                </div>
                <div class="lower-box">
                  {/* <!-- Social Box --> */}
                  <ul class="social-box">
                    <li><Link to=""><i class="ri-facebook-fill"></i></Link></li>
                    <li><Link to=""><i class="ri-twitter-fill"></i></Link></li>
                    <li><Link to=""><i class="ri-dribbble-fill"></i></Link></li>
                  </ul>
                  <div class="content">
                    <h5><Link to="">Adaim Mrala</Link></h5>
                    <div class="designation">IT Service Officer</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team
