import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { services, Data, imgUrl } from "../utils/environments/api";
import Loader from "../Components/Common/loader/Loaders.jsx";

const Service = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const [content, setContent] = useState({});
  const [serviceList, setServiceList] = useState([]);

  useEffect(() => {
    // Fetch data on component mount or ID change
    const fetchData = async () => {
      try {
        setLoading(true);

        const [serviceTypeRes, serviceContentRes] = await Promise.all([
          services.get("get_service_typ_api.php"),
          Data.get(`get_services_api.php?id=${id}`),
        ]);

        setServiceList(serviceTypeRes.data.data || []);
        setContent(serviceContentRes.data.data || {});
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  return (
    <>
      {loading && <Loader />}
      <div>
        <section className="page-title">
          <div className="pattern-layer-one"></div>
          <div className="auto-container">
            <h2>Services</h2>
            <ul className="page-breadcrumb">
              <li>
                <Link to="/">home&gt;</Link>
              </li>
              <li>Services</li>
            </ul>
          </div>
        </section>

        <div className="sidebar-page-container">
          <div className="auto-container service">
            <aside className="sidebar-side left-sidebar">
              <div className="sidebar sticky-top">
                <div className="sidebar-widget">
                  <ul className="service-list">
                    {serviceList.map((item) => (
                      <li key={item.servicetype_id}>
                        <Link to={`/Service?id=${item.servicetype_id}`}>
                          <span className="arrow fa fa-angle-double-right"></span>
                          {item.service}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="sidebar-widget contact-widget">
                  <div className="widget-content">
                    <h4>How Can We Help?</h4>
                    <p>If you need any help, please feel free to contact us.</p>
                    <ul>
                      <li>
                        <span className="icon">
                          <i className="ri-phone-line"></i>
                        </span>
                        +91 9100625636
                      </li>
                      <li>
                        <span className="icon">
                          <i className="ri-mail-line"></i>
                        </span>
                        info@vincosys.com
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </aside>

            <div className="content-side right-sidebar">
              <div className="services-detail">
                <div className="inner-box">
                  <h2>{content.title}</h2>
                  <div className="image">
                    <img src={`${imgUrl}${content.image1}`} alt="" />
                  </div>
                  <p
                    dangerouslySetInnerHTML={{ __html: content.description1 }}
                  ></p>
                  <h3>{content.sub_title}</h3>
                  <div className="image-two">
                    <img src={`${imgUrl}${content.image2}`} alt="" />
                  </div>
                  <p
                    dangerouslySetInnerHTML={{ __html: content.description2 }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <section className="info-section">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="info-column">
              <div className="inner-column">
                <div className="logo">
                  <Link to="">
                    <img
                      src="../../assets/Images/vincosys.png"
                      alt="Vincosys"
                    />
                  </Link>
                </div>
                <ul>
                  <li>
                    <strong>Address</strong>
                  </li>
                  <li>
                    #58-12-13/1, 2nd Floor, Sanjeevanagar, Rajahmundry 533105
                  </li>
                </ul>
              </div>
            </div>
            <div className="info-column">
              <div className="inner-column">
                <ul>
                  <li>
                    <strong>Phone</strong>
                  </li>
                  <li>+91 9100625636</li>
                </ul>
              </div>
            </div>
            <div className="info-column">
              <div className="inner-column">
                <ul>
                  <li>
                    <strong>E-Mail</strong>
                  </li>
                  <li>info@vincosys.com</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      </div>
    </>
  );
};

export default Service;
