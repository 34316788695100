import React from "react";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const Testimonials = () => {
  return (
    <div>
      <div class="testimonial-section">
        <div class="auto-container">
          {/* <!-- Sec Title --> */}
          <div class="sec-title">
            <div class="clearfix">
              <div class="pull-left">
                <div class="title">LATEST CASE STUDIES</div>
                <h2>
                  Reads Now Our Recent <br />
                  Projects Studies
                </h2>
              </div>
              <div class="pull-right">
                <div class="text">
                  Our goal is to help our companies maintain or achieve best-
                  in-class <br />
                  positions in their respective industries and our team works.
                </div>
              </div>
            </div>
          </div>
          <Carousel
            responsive={responsive}
            arrows={false}
            showDots={true}
            autoPlay={true}
            autoPlaySpeed={2000}
            class="testimonial-carousel owl-carousel owl-theme"
          >
            {/* <!-- Testimonial Block --> */}
            <div class="testimonial-block">
              <div
                class="inner-box"
                style={{
                  backgroundImage:
                    "url(../../assets/Images/background/pattern-4.png)",
                }}
              >
                <div class="upper-box">
                  <div class="icon">
                    <img
                      src="../../assets/Images/resource/testimonial-icon-1.png"
                      alt=""
                    />
                  </div>
                  <h4>Steelart</h4>
                  <div class="designation">Client of Company</div>
                </div>
                <div class="text">
                  "Very well thought out and articulate communication. Clear
                  milestones, deadlines and fast work. Patience. Infinite
                  patience. No shortcuts. Even if the client is being careless.
                  The best part...always solving problems with great original
                  ideas!."
                </div>
              </div>
            </div>

            {/* <!-- Testimonial Block --> */}
            <div class="testimonial-block">
              <div
                class="inner-box"
                style={{
                  backgroundImage:
                    "url(../../assets/Images/background/pattern-4.png)",
                }}
              >
                <div class="upper-box">
                  <div class="icon">
                    <img
                      src="../../assets/Images/resource/testimonial-icon-2.png"
                      alt=""
                    />
                  </div>
                  <h4>Figma Skills</h4>
                  <div class="designation">Client of Company</div>
                </div>
                <div class="text">
                  "Very well thought out and articulate communication. Clear
                  milestones, deadlines and fast work. Patience. Infinite
                  patience. No shortcuts. Even if the client is being careless.
                  The best part...always solving problems with great original
                  ideas!."
                </div>
              </div>
            </div>

            {/* <!-- Testimonial Block --> */}
            <div class="testimonial-block">
              <div
                class="inner-box"
                style={{
                  backgroundImage:
                    "url(../../assets/Images/background/pattern-4.png)",
                }}
              >
                <div class="upper-box">
                  <div class="icon">
                    <img
                      src="../../assets/Images/resource/testimonial-icon-1.png"
                      alt=""
                    />
                  </div>
                  <h4>Steelart</h4>
                  <div class="designation">Client of Company</div>
                </div>
                <div class="text">
                  "Very well thought out and articulate communication. Clear
                  milestones, deadlines and fast work. Patience. Infinite
                  patience. No shortcuts. Even if the client is being careless.
                  The best part...always solving problems with great original
                  ideas!."
                </div>
              </div>
            </div>

            {/* <!-- Testimonial Block --> */}
            <div class="testimonial-block">
              <div
                class="inner-box"
                style={{
                  backgroundImage:
                    "url(../../assets/Images/background/pattern-4.png)",
                }}
              >
                <div class="upper-box">
                  <div class="icon">
                    <img
                      src="../../assets/Images/resource/testimonial-icon-2.png"
                      alt=""
                    />
                  </div>
                  <h4>Figma Skills</h4>
                  <div class="designation">Client of Company</div>
                </div>
                <div class="text">
                  "Very well thought out and articulate communication. Clear
                  milestones, deadlines and fast work. Patience. Infinite
                  patience. No shortcuts. Even if the client is being careless.
                  The best part...always solving problems with great original
                  ideas!."
                </div>
              </div>
            </div>

            {/* <!-- Testimonial Block --> */}
            <div class="testimonial-block">
              <div
                class="inner-box"
                style={{
                  backgroundImage:
                    "url(../../assets/Images/background/pattern-4.png)",
                }}
              >
                <div class="upper-box">
                  <div class="icon">
                    <img
                      src="../../assets/Images/resource/testimonial-icon-1.png"
                      alt=""
                    />
                  </div>
                  <h4>Steelart</h4>
                  <div class="designation">Client of Company</div>
                </div>
                <div class="text">
                  "Very well thought out and articulate communication. Clear
                  milestones, deadlines and fast work. Patience. Infinite
                  patience. No shortcuts. Even if the client is being careless.
                  The best part...always solving problems with great original
                  ideas!."
                </div>
              </div>
            </div>

            {/* <!-- Testimonial Block --> */}
            <div class="testimonial-block">
              <div
                class="inner-box"
                style={{
                  BackgroundImage:
                    "url(../../assets/Images/background/pattern-4.png)",
                }}
              >
                <div class="upper-box">
                  <div class="icon">
                    <img
                      src="../../assets/Images/resource/testimonial-icon-2.png"
                      alt=""
                    />
                  </div>
                  <h4>Figma Skills</h4>
                  <div class="designation">Client of Company</div>
                </div>
                <div class="text">
                  "Very well thought out and articulate communication. Clear
                  milestones, deadlines and fast work. Patience. Infinite
                  patience. No shortcuts. Even if the client is being careless.
                  The best part...always solving problems with great original
                  ideas!."
                </div>
              </div>
            </div>
          </Carousel>
          <div class="lower-text">
            More than 5k customers share their thoughts to us. . .
            <Link to="">Read All Reviews</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
