import React from 'react'
import { Link } from 'react-router-dom'

const CalltoAction = () => {
  return (
    <div>
      <div  class="call-to-action-section">
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!-- Heading Column --> */}
            <div class="heading-column col-lg-8 col-md-12 col-sm-12">
              <div class="inner-column">
                <h2>
                  Preparing For Your Business <br />
                  Success With IT Solution
                </h2>
              </div>
            </div>
            {/* <!-- Button Column --> */}
            <div class="button-column col-lg-4 col-md-12 col-sm-12">
              <div class="inner-column">
                <Link to="" class="theme-btn btn-style-two"
                  ><span class="txt">Meet With Us</span></Link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalltoAction
