import React from "react";
import Case from "./Case";
import { Link } from "react-router-dom";
const Products = () => {
  return (
    <div>
      <div class="page-title">
        <div
          class="pattern-layer-one"
          // style={{
          //   backgroundImage:
          //     "url(../../assets/Images/background/pattern-16.png)",
          // }}
        ></div>
        <div class="auto-container">
          <h2>Products</h2>
          <ul class="page-breadcrumb">
            <li>
              <Link to="">home&gt;</Link>
            </li>
            <li>Products</li>
          </ul>
        </div>
      </div>
      <Case />
    </div>
  );
};

export default Products;
