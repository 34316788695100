import React from "react";
import { Link } from "react-router-dom";
import CareersSinglePage from "./CareersSinglePage";
const ServiceIot = () => {
  return (
    <div>
      <div className="page-title">
        <div
          className="pattern-layer-one"
          // style={{
          //   backgroundImage:
          //     "url(../../assets/Images/background/pattern-16.png)",
          // }}
        ></div>
        <div className="auto-container">
          <h2>CAREERS</h2>
          <ul className="page-breadcrumb">
            <li>
              <Link to="/">Home&gt;</Link>
            </li>
            <li>Careers</li>
          </ul>
        </div>
      </div>
      <div className="careerpage">
        <div className="auto-container">
          <div className="upper-section">
            <div className="row clearfix">
              <div className="image-column col-lg-7 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    <div className="title_name"> IOT DEVELOPER</div>
                    <div className="location">
                      <h2>
                        <i className="ri-crosshair-2-line"></i> #58-12-13/1, 2nd
                        Floor, Sanjeevanagar, Rajahmundry-533105.
                      </h2>
                      {/* <h2><i className="ri-money-rupee-circle-line"></i> 1 - 4 lacs</h2> */}
                    </div>
                    <p>We are looking for a IOT Developer </p>
                  </div>
                  <div className="jobdescript">
                    <div className="title">Job Description</div>
                    <p>
                      Design, code, and implement IoT solutions, enabling device
                      connectivity and data integration. Collaborate on
                      hardware-software integration, troubleshoot issues, and
                      optimize performance for scalable, secure IoT
                      applications.
                    </p>
                  </div>
                  <div className="jobdescript">
                    <div className="title">Job Requirements</div>
                    <ul>
                      <li>
                        Proficiency in languages like C, C++, Python, or
                        JavaScript; experience with embedded programming is
                        highly desirable.
                      </li>
                      <li>
                        Strong debugging skills to resolve connectivity issues
                        and optimize device performance.
                      </li>
                      <li>
                        1-3 years of hands-on experience in IoT development or
                        embedded systems (required for entry to mid-level
                        roles); additional experience may be required for senior
                        roles.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="info-column col-lg-5 col-md-12 col-sm-12">
                <div className="contact-form">
                  <form method="post" id="contact-form">
                    <CareersSinglePage />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceIot;
