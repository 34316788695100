import React from "react";
import { Link } from "react-router-dom";
import CareersSinglePage from "./CareersSinglePage";

const DigitalMarketing = () => {
  return (
    <div>
      <div className="page-title">
        <div
          className="pattern-layer-one"
          // style={{
          //   backgroundImage:
          //     "url(../../assets/Images/background/pattern-16.png)",
          // }}
        ></div>
        <div className="auto-container">
          <h2>CAREERS</h2>
          <ul className="page-breadcrumb">
            <li>
              <Link to="/">Home &gt;</Link>
            </li>
            <li>Careers</li>
          </ul>
        </div>
      </div>
      <div className="careerpage">
        <div className="auto-container">
          <div className="upper-section">
            <div className="row clearfix">
              <div className="image-column col-lg-7 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    <div className="title_name">Digital Marketing</div>
                    <div className="location">
                      <h2>
                        <i className="ri-crosshair-2-line"></i> #58-12-13/1, 2nd
                        Floor, Sanjeevanagar, Rajahmundry-533105.
                      </h2>
                      {/* <h2><i className="ri-money-rupee-circle-line"></i> 1 - 4 lacs</h2> */}
                    </div>
                    <p>We are looking for a Digital Marketing Team</p>
                  </div>
                  <div className="jobdescript">
                    <div className="title">Job Description</div>
                    <p>
                      Plan, execute, and optimize digital campaigns across
                      social media, email, and SEO. Analyze metrics to improve
                      engagement, drive traffic, and increase conversions
                      aligned with company objectives...
                    </p>
                  </div>
                  <div className="jobdescript">
                    <div className="title">Job Requirements</div>
                    <ul>
                      <li>
                        Strong understanding of digital marketing concepts,
                        including social media, SEO, SEM, email marketing, and
                        content marketing.
                      </li>
                      <li>
                        Proficiency in digital marketing tools such as Google
                        Analytics, Google Ads, social media platforms, and email
                        marketing software (e.g., Mailchimp, HubSpot).
                      </li>
                      <li>
                        Strong writing and editing skills for creating engaging
                        content across various digital channels...
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="info-column col-lg-5 col-md-12 col-sm-12">
                <div className="contact-form">
                  <form method="post" id="contact-form">
                    <CareersSinglePage />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalMarketing;
