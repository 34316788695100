import React from "react";
import { Link } from "react-router-dom";
import CareersSinglePage from "./CareersSinglePage";
const WebDev = () => {
  return (
    <div>
      <div className="page-title">
        <div
          className="pattern-layer-one"
          // style={{
          //   backgroundImage:
          //     "url(../../assets/Images/background/pattern-16.png)",
          // }}
        ></div>
        <div className="auto-container">
          <h2>CAREERS</h2>
          <ul className="page-breadcrumb">
            <li>
              <Link to="/">Home&gt;</Link>
            </li>
            <li>Careers</li>
          </ul>
        </div>
      </div>
      <div className="careerpage">
        <div className="auto-container">
          <div className="upper-section">
            <div className="row clearfix">
              <div className="image-column col-lg-7 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    <div className="title_name">Web Design And Development</div>
                    <div className="location">
                      <h2>
                        <i className="ri-crosshair-2-line"></i> #58-12-13/1, 2nd
                        Floor, Sanjeevanagar, Rajahmundry-533105.
                      </h2>
                      {/* <h2><i className="ri-money-rupee-circle-line"></i> 1 - 4 lacs</h2> */}
                    </div>
                    <p>We are looking for a Web Developer </p>
                  </div>
                  <div className="jobdescript">
                    <div className="title">Job Description</div>
                    <p>
                      Create, design, and maintain responsive websites.
                      Collaborate with clients to ensure usability, optimize
                      user experience, and implement best practices in HTML,
                      CSS, JavaScript, and CMS platforms....
                    </p>
                  </div>
                  <div className="jobdescript">
                    <div className="title">Job Requirements</div>
                    <ul>
                      <li>
                        Proficiency in HTML, CSS, JavaScript, and experience
                        with front-end frameworks (e.g., Bootstrap, React).
                      </li>
                      <li>
                        Familiarity with design software such as Adobe Creative
                        Suite (Photoshop, Illustrator) or Figma for UI/UX
                        design.
                      </li>
                      <li>
                        Strong analytical and troubleshooting abilities to
                        resolve technical issues and optimize website
                        performance.
                      </li>
                      <li>
                        1-3 years of experience in web design and development;
                        portfolio demonstrating previous work is highly
                        desirable.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="info-column col-lg-5 col-md-12 col-sm-12">
                <div className="contact-form">
                  <form method="post" id="contact-form">
                    <CareersSinglePage />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebDev;
