import { Link } from 'react-router-dom'
import React from 'react'
const About = () => {
  return (
      <div>
        <div class="about-section">
        <div class="auto-container">
          {/* <!-- Sec Title --> */}
          <div class="sec-title">
            <div class="title">ABOUT COMPANY</div>
            <h2>
              Empowering Innovation in  <br />
              the Digital Era.
            </h2>
          </div>
          <div class="row clearfix">
            {/* <!-- Content Column --> */}
            <div class="content-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="text">
                  At Vincosys, we specialize in transforming ideas into impactful digital solutions. From IoT solutions to mobile and web development, our team of experts drives business growth through cutting-edge technology, tailored services, and a commitment to excellence. We help businesses thrive in today’s dynamic tech landscape.
                  <ul>
                    <li>Commitment to Quality</li>
                    <li>Future-Focused Solutions</li>
                  </ul>
                </div>
                <div class="blocks-outer">
                  {/* <!-- Feature Block --> */}
                  <div class="feature-block">
                    <div class="inner-box">
                      <div class="icon"><i class="ri-award-fill"></i></div>
                      <h6>Experience</h6>
                      <div class="feature-text">
                        Our great team of more than 30 software experts.
                      </div>
                    </div>
                  </div>

                  {/* <!-- Feature Block --> */}
                  <div class="feature-block">
                    <div class="inner-box">
                      <div class="icon"><i class="ri-settings-2-line"></i></div>
                      <h6>Quick Support</h6>
                      <div class="feature-text">
                        We’ll help you test bold new ideas while sharing your.
                      </div>
                    </div>
                  </div>
                </div>

                <Link to="/AboutUs" class="lightbox-image theme-btn btn-style-one"><span class="txt">Read More</span></Link>
              </div>
            </div>

            {/* <!-- Images Column --> */}
            <div class="images-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="main">
                  <div class="pattern-layer">
                    </div>
                </div>
                <div class="images-outer parallax-scene-1">
                  <div class="image" data-depth="0.10">
                    <img src="../../assets/Images/resource/about-1.jpg" alt="" />
                  </div>
                  <div class="image-two" data-depth="0.30">
                    <img src="../../assets/Images/resource/about-2.jpg" alt="" />
                  </div>
                  <div class="image-three" data-depth="0.20">
                    <img src="../../assets/Images/resource/about-3.jpg" alt="" />
                  </div>
                  <div class="image-four" data-depth="0.30">
                    <img src="I../../assets/Images/resource/about-4.jpg" alt="" />
                  </div>
                </div>
              </div>
              <Link to="/Service1" class="learn"
                ><span
                //   class="arrow flaticon-long-arrow-pointing-to-the-right"
                ><i class="ri-arrow-right-line"></i></span
                >Learn More About Company</Link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default About