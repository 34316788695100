import React from "react";
import Case from "./Case";
import { Link } from "react-router-dom";
const Service1 = () => {
  return (
    <div>
      <Case />

      {/* SERVICE TWO */}
      <div class="services-section-two margin-top">
        <div class="auto-container">
          <div class="upper-box">
            <div class="icon-one"></div>
            <div class="icon-two"></div>
            <div class="icon-three"></div>
            {/* <!-- Sec Title --> */}
            <div class="sec-title light centered">
              <div class="title">WHO WE ARE</div>
              <h2>
                We deal with the aspects of <br />
                professional IT Services
              </h2>
            </div>
          </div>
          <div class="inner-container">
            <div class="row clearfix">
              {/* <!-- Service Block Two --> */}
              <div class="service-block-two col-lg-4 col-md-6 col-sm-12">
                <div class="inner-box">
                  <div class="shape-one"></div>
                  <div class="shape-two"></div>
                  <h5>
                    <Link to="/Service?id=3">Web Develpment</Link>
                  </h5>
                  <div class="text">
                    We carry more than just good coding skills. Our experience
                    makes us stand out from other web development.
                  </div>
                </div>
              </div>

              {/* <!-- Service Block Two --> */}
              <div class="service-block-two col-lg-4 col-md-6 col-sm-12">
                <div class="inner-box">
                  <div class="shape-one"></div>
                  <div class="shape-two"></div>
                  <h5>
                    <Link to="/Service?id=5">Mobile Development</Link>
                  </h5>
                  <div class="text">
                    Create complex enterprise software, ensure reliable software
                    integration, modernise your legacy system.
                  </div>
                </div>
              </div>

              {/* <!-- Service Block Two --> */}
              <div class="service-block-two serv-margin col-lg-4 col-md-6 col-sm-12">
                <div class="inner-box">
                  <div class="shape-one"></div>
                  <div class="shape-two"></div>
                  <h5>
                    <Link to="/Service?id=2">UI/UX Design</Link>
                  </h5>
                  <div class="text">
                    Build the product you need on time with an experienced team
                    that uses a clear and effective design process.
                  </div>
                </div>
              </div>
              <div class="service-block-two col-lg-4 col-md-6 col-sm-12 srvimg">
                <img src="../../assets/Images/service/servicepage.jpg" alt="" />
              </div>
            </div>
            <div
              className="row clearfix"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {/* <!-- Service Block Two --> */}
              <div class="service-block-two col-lg-4 col-md-6 col-sm-12">
                <div class="inner-box">
                  <div class="shape-one"></div>
                  <div class="shape-two"></div>
                  <h5>
                    <Link to="/Service?id=1">QA & Testing</Link>
                  </h5>
                  <div class="text">
                    Turn to our experts to perform comprehensive, multi-stage
                    testing and auditing of your software.
                  </div>
                </div>
              </div>

              {/* <!-- Service Block Two --> */}
              <div class="service-block-two col-lg-4 col-md-6 col-sm-12">
                <div class="inner-box">
                  <div class="shape-one"></div>
                  <div class="shape-two"></div>
                  <h5>
                    <Link to="/Service?id=4">IT Counsultancy</Link>
                  </h5>
                  <div class="text">
                    Trust our top minds to eliminate workflow pain points,
                    implement new tech, and consolidate app portfolios.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Service1;
