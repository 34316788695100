import React from "react";
import { Link } from "react-router-dom";
const Blog = () => {
  return (
    <div>
      <div class="news-section">
        <div class="auto-container">
          {/* <!-- Sec Title --> */}
          <div class="sec-title centered">
            <div class="title">Our Blog</div>
            <h2>
              Click Out Our <br />
              Latest News & Artical
            </h2>
          </div>
          <div class="row clearfix">
            {/* <!-- News Block --> */}
            <div class="news-block col-lg-3 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <Link to="">
                    <img src="../../assets/Images/resource/news-1.jpg" alt="" />
                  </Link>
                </div>
                <div class="lower-content">
                  {/* <div class="post-date">
                    22 <span>DEC</span>
                  </div>
                  <ul class="post-meta">
                    <li>
                      <span class="icon">
                        <i class="ri-chat-3-line"></i>
                      </span>
                      3 comments
                    </li>
                    <li>
                      <span class="icon">
                        <i class="ri-user-line"></i>
                      </span>
                      Admin
                    </li>
                  </ul> */}
                  <h6>
                    <Link to=""> IoT Solutions</Link>
                  </h6>
                  <div class="text">
                    In today’s digital era, IoT solutions have become crucial for businesses looking to leverage connected technology ...
                  </div>
                  <Link to="/Blog1" class="read-more">
                    Read More
                    <span class="arrow">
                      <i class="ri-arrow-right-fill"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div class="news-block col-lg-3 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <Link to="">
                    <img src="../../assets/Images/resource/news-1.jpg" alt="" />
                  </Link>
                </div>
                <div class="lower-content">
                  {/* <div class="post-date">
                    22 <span>DEC</span>
                  </div>
                  <ul class="post-meta">
                    <li>
                      <span class="icon">
                        <i class="ri-chat-3-line"></i>
                      </span>
                      3 comments
                    </li>
                    <li>
                      <span class="icon">
                        <i class="ri-user-line"></i>
                      </span>
                      Admin
                    </li>
                  </ul> */}
                  <h6>
                    <Link to=""> R&D Services</Link>
                  </h6>
                  <div class="text">
                    Research and development (R&D) services are essential for any IT company that seeks to stay at the forefront of technology ...
                  </div>
                  <Link to="/Blog2" class="read-more">
                    Read More
                    <span class="arrow">
                      <i class="ri-arrow-right-fill"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>

            {/* <!-- News Block --> */}
            <div class="news-block col-lg-3 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <Link to="">
                    <img src="../../assets/Images/resource/news-2.jpg" alt="" />
                  </Link>
                </div>
                <div class="lower-content">
                  {/* <div class="post-date">
                    15 <span>DEC</span>
                  </div>
                  <ul class="post-meta">
                    <li>
                      <span class="icon">
                        <i class="ri-chat-3-line"></i>
                      </span>
                      3 comments
                    </li>
                    <li>
                      <span class="icon">
                        <i class="ri-user-line"></i>
                      </span>
                      Admin
                    </li>
                  </ul> */}
                  <h6>
                    <Link to="">
                      Web Development Services
                    </Link>
                  </h6>
                  <div class="text">
                    An engaging, high-performing website is crucial for any modern business, and vincosys specializes in delivering  ...
                  </div>
                  <Link to="/Blog3" class="read-more">
                    Read More
                    <span class="arrow">
                      <i class="ri-arrow-right-fill"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>

            {/* <!-- News Block --> */}
            <div class="news-block col-lg-3 col-md-6 col-sm-12">
              <div class="inner-box">
                <div class="image">
                  <Link to="">
                    <img src="../../assets/Images/resource/news-3.jpg" alt="" />
                  </Link>
                </div>
                <div class="lower-content">
                  {/* <div class="post-date">
                    10 <span>oct</span>
                  </div>
                  <ul class="post-meta">
                    <li>
                      <span class="icon">
                        <i class="ri-chat-3-line"></i>
                      </span>
                      3 comments
                    </li>
                    <li>
                      <span class="icon">
                        <i class="ri-user-line"></i>
                      </span>
                      Admin
                    </li>
                  </ul> */}
                  <h6>
                    <Link to="">
                      Mobile App Development
                    </Link>
                  </h6>
                  <div class="text">
                    Mobile app development has become essential in today’s connected world, offering businesses a way to ...
                  </div>
                  <Link to="/Blog4" class="read-more">
                    Read More
                    <span class="arrow">
                      <i class="ri-arrow-right-fill"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
