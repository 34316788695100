import React from "react";
import CareersSinglePage from "./CareersSinglePage";
import { Link } from "react-router-dom";
const ProductDev = () => {
  return (
    <div>
      <div className="page-title">
        <div
          className="pattern-layer-one"
          // style={{
          //   backgroundImage:
          //     "url(../../assets/Images/background/pattern-16.png)",
          // }}
        ></div>
        <div className="auto-container">
          <h2>CAREERS</h2>
          <ul className="page-breadcrumb">
            <li>
              <Link to="/">Home &gt;</Link>
            </li>
            <li>Careers</li>
          </ul>
        </div>
      </div>
      <div className="careerpage">
        <div className="auto-container">
          <div className="upper-section">
            <div className="row clearfix">
              <div className="image-column col-lg-7 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    <div className="title_name"> Product Development</div>
                    <div className="location">
                      <h2>
                        <i className="ri-crosshair-2-line"></i> #58-12-13/1, 2nd
                        Floor, Sanjeevanagar, Rajahmundry-533105.
                      </h2>
                      {/* <h2><i className="ri-money-rupee-circle-line"></i> 1 - 4 lacs</h2> */}
                    </div>
                    <p>We are looking for a Product Developer </p>
                  </div>
                  <div className="jobdescript">
                    <div className="title">Job Description</div>
                    <p>
                      Oversee the product lifecycle from concept to launch.
                      Conduct market research, design prototypes, and
                      collaborate with cross-functional teams to ensure
                      successful product delivery and alignment with customer
                      needs....
                    </p>
                  </div>
                  <div className="jobdescript">
                    <div className="title">Job Requirements</div>
                    <ul>
                      <li>
                        Proficiency in project management methodologies (e.g.,
                        Agile, Scrum) to effectively manage product development
                        timelines and deliverables.
                      </li>
                      <li>
                        Excellent verbal and written communication skills for
                        presenting ideas, findings, and updates to stakeholders.
                      </li>
                      <li>
                        Strong analytical skills to conduct market research and
                        identify customer needs, trends, and competitive
                        landscape.
                      </li>
                      <li>
                        Strong critical thinking and problem-solving skills to
                        address challenges throughout the product development
                        process.
                      </li>
                      <li>
                        2-5 years of experience in product development or
                        related fields; a portfolio showcasing previous projects
                        is beneficial.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="info-column col-lg-5 col-md-12 col-sm-12">
                <div className="contact-form">
                  <form method="post" id="contact-form">
                    <CareersSinglePage />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDev;
