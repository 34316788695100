import React from "react";
import CareersSinglePage from "./CareersSinglePage";
import { Link } from "react-router-dom";
const MobileDev = () => {
  return (
    <div>
      <div className="page-title">
        <div
          className="pattern-layer-one"
          // style={{
          //   backgroundImage:
          //     "url(../../assets/Images/background/pattern-16.png)",
          // }}
        ></div>
        <div className="auto-container">
          <h2>CAREERS</h2>
          <ul className="page-breadcrumb">
            <li>
              <Link to="/">Home &gt;</Link>
            </li>
            <li>Careers</li>
          </ul>
        </div>
      </div>
      <div className="careerpage">
        <div className="auto-container">
          <div className="upper-section">
            <div className="row clearfix">
              <div className="image-column col-lg-7 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    <div className="title_name">
                      {" "}
                      Mobile Application Development
                    </div>
                    <div className="location">
                      <h2>
                        <i className="ri-crosshair-2-line"></i> #58-12-13/1, 2nd
                        Floor, Sanjeevanagar, Rajahmundry-533105.
                      </h2>
                      {/* <h2><i className="ri-money-rupee-circle-line"></i> 1 - 4 lacs</h2> */}
                    </div>
                    <p>We are looking for a Mobile Developer</p>
                  </div>
                  <div className="jobdescript">
                    <div className="title">Job Description</div>
                    <p>
                      Design, develop, and maintain mobile applications for iOS
                      and Android platforms. Collaborate with cross-functional
                      teams to ensure functionality, optimize performance, and
                      enhance user experience through innovative solutions...
                    </p>
                  </div>
                  <div className="jobdescript">
                    <div className="title">Job Requirements</div>
                    <ul>
                      <li>
                        Proficiency in programming languages such as Swift (for
                        iOS), Kotlin or Java (for Android), and familiarity with
                        cross-platform frameworks (e.g., React Native, Flutter)
                      </li>
                      <li>
                        Experience with integrated development environments
                        (IDEs) like Xcode for iOS and Android Studio for Android
                        development.
                      </li>
                      <li>
                        Experience with RESTful APIs and third-party libraries
                        to enhance app functionality and data integration.
                      </li>
                      <li>
                        Strong analytical and problem-solving skills to address
                        technical challenges and optimize application
                        performance.
                      </li>
                      <li>
                        1-3 years of experience in mobile application
                        development; a portfolio showcasing previous projects is
                        a plus.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="info-column col-lg-5 col-md-12 col-sm-12">
                <div className="contact-form">
                  <form method="post" id="contact-form">
                    <CareersSinglePage />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileDev;
