import React from "react";
import { Link } from "react-router-dom";
import "../../assets/css/style.css";
import { useState } from "react";
import { useEffect } from "react";
import "../../assets/css/responsive.css";
import "../../assets/css/style2.css";
import { services } from "../../utils/environments/api";
import { productType } from "../../utils/environments/api";

const Footer = () => {
  const [service, setService] = useState([]);
  const [type, setType] = useState([]);

  useEffect(() => {
    ProductType();
  }, []);
  const ProductType = async () => {
    try {
      const res = await productType.get(`get_product_typ_api.php`);
      console.log("API Response:", res);

      if (res.data.data) {
        setType(res.data.data);
      } else {
        console.error("Invalid response format:", res);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  useEffect(() => {
    serviceData();
  }, []);
  const serviceData = async () => {
    try {
      const res = await services.get("get_service_typ_api.php");
      console.log("API Response:", res);

      if (res.data.data) {
        setService(res.data.data);
      } else {
        console.error("Invalid response format:", res);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  return (
    <div>
      {/* Content before waves */}

      {/* Waves Container */}
      <div className="wave">
        <svg
          className="waves_floods"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="0"
              fill="rgba(16, 24, 75, 0.7)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="3"
              fill="rgba(16, 24, 75, 0.5)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="5"
              fill="rgba(16, 24, 75, 0.3)"
            />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#10224b" />
          </g>
        </svg>
      </div>

      {/* Footer content */}
      <div>
        <footer class="main-footer">
          {/* <!--Waves end--> */}
          <div class="auto-container">
            {/* <!--Widgets Section--> */}
            <div class="widgets-section">
              <div class="row clearfix">
                <div class="footer-column col-lg-3 col-md-6 col-sm-12">
                  <div class="footer-widget logo-widget">
                    <div class="logo">
                      <Link to="/">
                        <img src="../../assets/Images/vincosys.png" alt="" />
                      </Link>
                    </div>
                    <div class="text">
                      We are the best world Information Technology Company.
                      Providing the highest quality in hardware & Network
                      solutions. About more than 25 years of experience and 1000
                      of innovative achievements.
                    </div>
                    {/* <!-- Social Box --> */}
                    <ul class="social-box">
                      <li>
                        <Link
                          to="https://www.linkedin.com/company/vincosys/"
                          target="_blank"
                        >
                          <i class="ri-linkedin-fill"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://www.instagram.com/vincosys/profilecard/?igsh=dTFnaG1nNGpjamxm"
                          target="_blank"
                        >
                          <i class="ri-instagram-line"></i>
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="">
                          <i class="ri-dribbble-fill"></i>
                        </Link>
                      </li> */}
                      <li>
                        <a
                          href="https://wa.me/919966962597?text=Hi%20there%2C%20I%20would%20like%20to%20contact%20you!"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="ri-whatsapp-line"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* <!-- Footer Column --> */}
                <div class="footer-column col-lg-3 col-md-6 col-sm-12">
                  <div class="footer-widget links-widget">
                    <h5>Services</h5>
                    <ul class="list-link">
                      {service.map((item) => (
                        <li key={item.servicetype_id}>
                          <Link to={`/Service?id=${item.servicetype_id}`}>
                            {item.service}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div class="footer-column col-lg-3 col-md-6 col-sm-12">
                  <div class="footer-widget links-widget">
                    <h5>Products</h5>
                    <ul class="list-link">
                      {type.map((item) => (
                        <li key={item.producttype_id}>
                          <Link
                            to={`/ProductSinglePage?id=${item.producttype_id}`}
                            class="arrow"
                          >
                            {item.product}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div class="footer-column col-lg-3 col-md-6 col-sm-12">
                  <div class="footer-widget contact-widget">
                    <h5>Contact Us</h5>
                    <ul className="pad">
                      <li>
                        <span class="icon">
                          <i class="ri-map-pin-line"></i>
                        </span>
                        <strong>Address</strong>
                        #58-12-13/1, 2nd Floor, Sanjeevanagar,
                        Rajahmundry-533105,
                      </li>
                      <li>
                        <span class="icon">
                          <i class="ri-phone-line"></i>
                        </span>
                        <strong>Phone</strong>
                        <Link to="tel:9100625636">9100625636</Link>
                      </li>
                      <li>
                        <span class="icon">
                          <i class="ri-mail-open-line"></i>
                        </span>
                        <strong>E-Mail</strong>
                        <Link to="mailto:info@vincosys.com ">
                          info@vincosys.com{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Footer Bottom --> */}
            <div class="footer-bottom">
              <div class="auto-container">
                <div class="row clearfix">
                  {/* <!-- Column --> */}
                  <div class="column col-lg-6 col-md-12 col-sm-12">
                    <div class="copyright">
                      Copyright <i class="ri-copyright-line"></i> 2024 by
                      vincosys. All Rights Reserved.
                    </div>
                  </div>
                  {/* <!-- Column --> */}
                  <div class="column col-lg-6 col-md-12 col-sm-12">
                    <ul class="footer-nav">
                      <li>
                        <Link to="/AboutUs">About Us</Link>
                      </li>
                      <li>
                        <Link to="/Service1">Services</Link>
                      </li>
                      <li>
                        <Link to="/Privacy">Privacy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};
export default Footer;
