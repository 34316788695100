import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { products, imgUrl, productType } from "../utils/environments/api";
import Loader from "../Components/Common/loader/Loaders.jsx";

const ProductSinglePage = () => {
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const [product, setProduct] = useState({});
  const [type, setType] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const productResponse = await products.get(
          `get_products_api.php?id=${id}`
        );
        const typeResponse = await productType.get("get_product_typ_api.php");

        if (productResponse.data?.data) {
          setProduct(productResponse.data.data);
        } else {
          console.error("Invalid product response format:", productResponse);
        }

        if (typeResponse.data?.data) {
          setType(typeResponse.data.data);
        } else {
          console.error("Invalid type response format:", typeResponse);
        }
      } catch (error) {
        console.error("Error during API call:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id, location]);

  return (
    <>
      {loading && <Loader />}
      <div>
        <section className="page-title">
          <div
            className="pattern-layer-one"
            // style={{
            //   backgroundImage:
            //     "url(../../assets/Images/background/pattern-16.png)",
            // }}
          ></div>
          <div className="auto-container">
            <h2>PRODUCT DETAIL</h2>
            <ul className="page-breadcrumb">
              <li>
                <Link to="/">home &gt;</Link>
              </li>
              <li>Product Detail</li>
            </ul>
          </div>
        </section>

        <section className="project-detail-section">
          <div className="auto-container">
            <div className="upper-section">
              <div className="row clearfix">
                <div className="image-column col-lg-8 col-md-12 col-sm-12">
                  <div className="inner-column">
                    <div className="sec-title">
                      <div className="title">{product.title}</div>
                      <h2>{product.heading}</h2>
                    </div>
                    <div className="image">
                      <img
                        src={`${imgUrl}${product.image}`}
                        alt={product.title || "Product Image"}
                      />
                    </div>
                  </div>
                </div>

                <div className="info-column col-lg-4 col-md-12 col-sm-12">
                  <aside
                    className="sidebar sticky-top"
                    style={{ position: "sticky", top: 0, zIndex: 10 }}
                  >
                    <div className="sidebar-widget">
                      <ul className="service-list">
                        {type.map((item) => (
                          <li key={item.producttype_id}>
                            <Link
                              to={`/ProductSinglePage?id=${item.producttype_id}`}
                            >
                              <span className="arrow fa fa-angle-double-left"></span>
                              {item.product}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </aside>
                </div>
              </div>
            </div>

            <div className="lower-section">
              <h3>{product.content_heading}</h3>
              <div dangerouslySetInnerHTML={{ __html: product.content }}></div>
              <h3>{product.subcontent_heading}</h3>
              <div
                dangerouslySetInnerHTML={{ __html: product.sub_content }}
              ></div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ProductSinglePage;
