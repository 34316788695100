import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { profileData } from "../utils/environments/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ServiceIot from "./ServiceIot";

const CareersSinglePage = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    number: "",
    expected_ctc: "",
    techos: "",
    location: "",
  });

  // Function to handle input changes for text fields
  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const postData = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
      const res = await profileData.post("post_profile_api.php", form);

      console.log("API Response:", res);
      if (res.data.status === true) {
        toast("Submitted Successfully!");
      } else {
        const errorMessage = res.data.message || "An unknown error occurred";
      }
    } catch (error) {
      console.error("Error during post:", error);
      const errorDetail = error.response
        ? JSON.stringify(error.response.data)
        : error.message;
    }
  };

  return (
    <div>
      <div className="row clearfix inner-column">
        <h4>Send Us Your Profile</h4>
        <div className="form-group col-lg-6 col-md-6 col-sm-12">
          <label>Your name *</label>
          <input
            type="text"
            name="name"
            placeholder=""
            required
            onChange={onChange}
          />
          <br />
        </div>

        <div className="form-group col-lg-6 col-md-6 col-sm-12">
          <label>Email address *</label>
          <input
            type="text"
            name="email"
            placeholder=""
            required
            onChange={onChange}
          />
          <br />
        </div>

        <div className="form-group col-lg-6 col-md-6 col-sm-12">
          <label>Phone number *</label>
          <input
            type="text"
            name="number"
            placeholder=""
            required
            onChange={onChange}
          />
          <br />
        </div>
        <div className="form-group col-lg-6 col-md-6 col-sm-12">
          <label>Technologies *</label>
          <select name="techos" class="form-control" onChange={onChange}>
            <option value="">Select Technology</option>
            <option value="IoT Services">IoT Services</option>
            <option value="R & D Services">R & D Services</option>
            <option value="Digital Marketing">Digital Marketing</option>
            <option value="Web Design & Development">
              Web Design & Development
            </option>
            <option value="Mobile Application Development">
              Mobile Application Development
            </option>
            <option value="Product Development">Product Development</option>
          </select>
        </div>

        <div className="form-group col-lg-6 col-md-6 col-sm-12">
          <label>Expected CTC (Lakhs)*</label>
          <input
            type="text"
            name="expected_ctc"
            placeholder=""
            required
            onChange={onChange}
          />
          <br />
        </div>

        <div className="form-group col-lg-6 col-md-6 col-sm-12">
          <label>Location *</label>
          <input
            type="text"
            name="location"
            placeholder=""
            required
            onChange={onChange}
          />
        </div>

        <div className="form-group text-center col-lg-12 col-md-12 col-sm-12">
          <button
            className="theme-btn btn-style-three"
            type="button"
            onClick={postData}
          >
            <ToastContainer />
            <span className="txt">Send Now</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CareersSinglePage;
